<template>
  <DialogFormWrapper>
    <template #form>
      <v-form
        id="addBdoCardTemplate"
        ref="addBdoCardTemplate"
        v-model="isFormValid"
        class="custom-form pt-3"
        @submit.prevent="submitNewBdoCardTemplateForm"
      >
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="name"
              outlined
              :rows="3"
              auto-grow
              :rules="[rules.required]"
              label="Nazwa szablonu"
              hide-details
              placeholder="Wpisz nazwę szablonu"
            />
          </v-col>
        </v-row>
        <BdoCardTemplate
          class="mt-2"
          :data="bdoCardTemplate"
          @update="updateValue"
        />
      </v-form>
    </template>
    <template #submit>
      <v-btn
        color="primary"
        class="base-hover"
        :disabled="!isFormValid"
        type="submit"
        form="addBdoCardTemplate"
      >
        Zapisz szablon
      </v-btn>
    </template>
  </DialogFormWrapper>
</template>

<script>
import DialogFormWrapper from './Partials/DialogFormWrapper'
import BdoCardTemplate from './Inputs/BdoCardTemplate'
import { mapState, mapActions } from 'vuex'
import { BdoCardTemplate as BdoCardTemplateModel } from '../../models'
import rules from '../../utils/validators'
import set from 'lodash/set'

export default {
  components: {
    DialogFormWrapper,
    BdoCardTemplate
  },

  data() {
    return {
      rules,
      isFormValid: true,
      name: null,
      bdoCardTemplate: new BdoCardTemplateModel()
    }
  },
  computed: {
    ...mapState({
      bdoCard: state => state.layout.dialog.item
    }),
  },
  mounted() {
    this.bdoCardTemplate = new BdoCardTemplateModel(this.bdoCard)
  },
  methods: {
    ...mapActions({
      addBdoCardTemplate: 'bdoCards/addBdoCardTemplate',
      closeDialog: 'layout/closeDialog',
      showSnackbar: 'snackbar/showSnackbar'
    }),
    submitNewBdoCardTemplateForm() {
      this.$refs.addBdoCardTemplate.validate()
      const params = { name: this.name, ...this.bdoCardTemplate, senderCity: this.bdoCardTemplate?.senderCity?.key }
      this.addBdoCardTemplate(params).then(() => {
        this.closeDialog()
        this.showSnackbar({ message: ['Zapisano szablon'] })
      })
    },
    updateValue({ key, value }) {
      set(this.bdoCardTemplate, key, value)
    }
  }
}
</script>
